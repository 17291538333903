<template>
	<div>
		<div class="queryBox mb20 ">
			<div class="mb20">
				<span class="mr20">共{{payList.total_count}}个</span>
				<span class="mr20">启用： {{payList.enable_count}}个</span>
				<span class="mr20">停用： {{payList.total_count - payList.enable_count}}个</span>
				<span class="mr20">在线： {{payList.online_count}}个</span>
			</div>
			<div class="mb20">
				<el-button class="mr20" size="mini" type="primary" @click="newqr('new')">新增</el-button>
				<el-button class="mr20" size="mini" type="warning" @click="stateAll(0)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量停用
				</el-button>
				<el-button class="mr20" size="mini" type="success" @click="stateAll(1)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量启用
				</el-button>
				<el-button size="mini" type="danger" @click="deleteAll()" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量删除
				</el-button>
			</div>
			<div class="flexX flexcenter">
				<span>状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="(item, index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<!-- <span>金额：</span>
				<el-select size="mini" class="mr20" v-model="money" clearable placeholder="请选择金额">
					<el-option v-for="(item) in moneyArr" :key="item" :label="item" :value="item"></el-option>
				</el-select> -->
				<el-button size="mini" type="primary" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" @click="getData"
					:disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" border="" stripe style="width: 100%" v-loading="loading"
				@selection-change="handleSelectionChange" size="mini" :show-overflow-tooltip="true">
				<el-table-column type="selection" align="center" width="55"></el-table-column>
				<el-table-column type="index" label="序号" align="center" width="120px">
					<template slot-scope="scope">
						<div class="errorDiv" v-if="scope.row.id == index" v-for="(item, index) in errorArr">{{ item }}
						</div>
						<span>{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="account" label="账号" align="center"></el-table-column>
				<el-table-column prop="true_name" label="真实姓名" align="center"></el-table-column>
				<!-- <el-table-column prop="nickname" label="昵称" align="center"></el-table-column> -->
				<!-- <el-table-column prop="telephone" label="手机号码" align="center"></el-table-column> -->
				<!-- <el-table-column prop="ali_userid" label="UserId" align="center"></el-table-column> -->
				<el-table-column prop="eve_used_money" label="日收上限" align="center"></el-table-column>
				<el-table-column prop="eve_used_num" label="日收笔数" align="center"></el-table-column>
				<!-- <el-table-column prop="callback_id" label="监控ID" align="center"></el-table-column> -->
				
				<el-table-column label="启停状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.state == 0 ? 'csuccess' : 'cdanger'">{{ scope.row.state == 0 ? '启用' : '禁用' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="在线状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.is_online == 1 ? 'csuccess' :scope.row.is_online == 2?'cwarning':'cdanger'">{{  scope.row.is_online == 1?'在线': scope.row.is_online == 2?'掉线':'离线'}}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="可收/已收(笔数)" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.total_num == scope.row.used_num ? 'cdanger' :'csuccess'">{{scope.row.total_num}} / {{scope.row.used_num}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="match_gold" label="匹配金额" align="center"></el-table-column>
				<el-table-column prop="gold" label="收款金额" align="center"></el-table-column>
				<el-table-column label="收款二维码" align="center">
					<template slot-scope="scope" v-if="scope.row.qrcode_link">
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.qrcode_link != ''"
							:src="scope.row.qrcode_link"
							fit="fill"
							:preview-src-list="[scope.row.qrcode_link]">
						</el-image>
					</template>
				</el-table-column>
				<!-- <el-table-column label="好友二维码" align="center">
					<template slot-scope="scope" v-if="scope.row.frcode_link">
						<el-image
							style="width: 30px; height: 50px"
							v-if="scope.row.frcode_link != ''"
							:src="scope.row.frcode_link"
							fit="fill"
							:preview-src-list="[scope.row.frcode_link]">
						</el-image>
					</template>
				</el-table-column> -->
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div >
							<el-link type="primary" @click="edit(scope.row)">编辑</el-link>
							</br>
							<el-button class="mt10" :disabled="operating" :type="scope.row.state == 0 ? 'warning' : 'success'" size="mini" @click="setState(scope.row)">{{ scope.row.state == 0 ? '停用' : '启用' }}</el-button>
							</br>
							<el-button class="mt10" :disabled="operating" type="danger" size="mini" @click="deleteQr(scope.row)">删除</el-button>
							
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog  center class="dialog" top="150px" :visible.sync="dialogVisible" @close="close" width="30%"
			:close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="textCenter">
					<div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">账号：</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入账号"
								v-model="dataDetails.account"></el-input>
						</div>
						<!-- <div class="flexX flexcenter mb20">
							<span style="width: 100px;">昵称：</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入昵称"
								v-model="dataDetails.nickname"></el-input>
						</div> -->
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">真实姓名：</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入真实姓名"
								v-model="dataDetails.true_name"></el-input>
						</div>
						<!-- <div class="flexX flexcenter mb20">
							<span style="width: 100px;">手机号码：</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入手机号码"
								v-model="dataDetails.telephone"></el-input>
						</div> -->
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">日收上限:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入日收上限"
								v-model="dataDetails.eve_used_money"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">日收笔数:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入日收笔数"
								v-model="dataDetails.eve_used_num"></el-input>
						</div>
						<!-- <div class="flexX flexcenter mb20">
							<span style="width: 100px;">UserId:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入UserId"
								v-model="dataDetails.ali_userid"></el-input>
						</div> -->
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">状态：</span>
							<el-switch @change="changeAliState" v-model="aliState" active-text="启用"
								inactive-text="禁用"></el-switch>
						</div>
						<!-- <div class="flexX flexcenter mb20">
							<span style="width: 100px;">监控ID: </span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入监控ID"
								v-model="dataDetails.callback_id"></el-input>
						</div> -->
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">收款二维码：</span>
							<div>
								<el-button class="mr20" size="mini" type="success" @click="showBigImg('收款二维码',fileurl)" :disabled="!fileurl">查看大图 </el-button>
							</div>
							<el-upload action="" :show-file-list="false" :http-request="resolveQR">
								<img slot="trigger" v-if="fileurl" class="upload-img" :src="fileurl">
								<el-button v-else type="success">上传</el-button>
							</el-upload>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">匹配金额:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入收款金额" v-model="dataDetails.match_gold"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">收款金额:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入收款金额" v-model="dataDetails.gold"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">可收笔数:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入可收笔数" v-model="dataDetails.total_num"></el-input>
						</div>
						<!-- <div class="flexX flexcenter mb20">
							<span style="width: 100px;">好友二维码：</span>
							<div>
								<el-button class="mr20" size="mini" type="success" @click="showBigImg('好友二维码',fileurlFriends)" :disabled="!fileurlFriends">查看大图 </el-button>
							</div>
							<el-upload action="" :show-file-list="false" :http-request="resolveQRFriends">
								<img slot="trigger" v-if="fileurlFriends" class="upload-img" :src="fileurlFriends">
								<el-button v-else type="success">上传</el-button>
							</el-upload>
						</div> -->
					</div>

					<el-button size="mini" type="primary" @click="sure()"   :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" :disabled="disLoading">确定</el-button>
					<el-button size="mini" @click="close"   :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" :disabled="disLoading">关闭</el-button>
				</div>
			</div>
			<el-dialog :title="qrImgTitle" top="150px" width ="30%" id ='myDialog' :visible.sync="innerVisibleImg" append-to-body @opened="opened" center>
				<div class="image-dialog">
				<el-image  :src="showImg"></el-image></div>
			</el-dialog>
		</el-dialog>
		<div @click="closePopup()" class="popup" :class="{ 'show': showPopup }">
			<div id="qr" class="qrcode" ref="qrCodeUrl"></div>
		</div>
	</div>
</template>

<script>
import local from '@/util/local'
import {
	isEmpty,
	isPositiveNumber,
	isMoney,
	isMobile
} from '@/util/validate'
import {
	Message
} from 'element-ui'
import QRCode2 from 'qrcodejs2'
import QrCode from '../../../node_modules/qrcode-decoder/dist/index'
// import QrCode from '../node_modules/qrcode-decoder/dist/index';
export default {
	data() {
		return {
			tableData: [],
			height: 0,
			loading: false,
			disLoading: false,
			pageSizesArr: [20, 50, 100, 200],
			pageSize: 20,
			total: 0,
			page: 1,
			state: '',
			stateArr: [{
				key: '',
				val: "全部"
			},
			{
				key: '0',
				val: "启用"
			},
			{
				key: '1',
				val: "停用"
			}
			],
			money: '',
			moneyArr: [],
			id: '',
			operating: false, //正在操作
			multipleSelection: [], //选中数组
			showPopup: false,
			errorArr: {},
			fileList: [],
			uploadData: {},
			upErrorArr: [],


			pay_type: '',
			item: {},
			storeInfo: {},
			dialogTitle: "",
			dialogType: '',
			dialogVisible: false,
			dialogloading: false,
			closeModal: false, //是否点击弹框 关闭，
			dataDetails: {
				true_name: "",
				account_branch: "",
				card: "",
				bank: "",
				telephone: "",
				state: 0,
				province: "",
				city: "",
				login_password: "",
				pay_password: "",
				account: '',
				bank_name: '',
				nickname: '',
				data: {},
				callback_id: '',
				year_total_money: '',
				eve_used_money: '',
				eve_used_num: '',
				qrcode_url: '',
				frcode_link:'',
				qrcode_link:'',
				gold: '',
				total_num: '',
				match_gold: '',
			},
			innerVisibleImg:false,
			aliState: true,
			fileurl: null,
			fileurlFriends:null,
			showImg:null,
			payList:{},
			qrImgTitle:'',
		};
	},
	created() {
		this.payTypeAll(this.$route.meta.pay_type)
		
		if (this.$route.query.id) {
			this.id = this.$route.query.id;
		}
		if (this.$route.query.money) {
			this.money = this.$route.query.money;
		}
		if (this.$route.query.state) {
			this.state = this.$route.query.state;
		}
		this.uploadData = {
			username: local.get('bpcusername'),
			tid: local.get('bpctid'),
			store_id: this.id,
			login_type: 2,
		};
		// this.getStoreMoneyGroup()
		this.alipayList()
		this.$nextTick(() => {
			let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
			let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
			let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
			let documentHeight = document.documentElement.clientHeight
			this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
		})
	},
	filters: {
		tofixed: function (value) {
			if (value || value == 0) {
				// console.log('sssssssssss', (value * 1).toFixed(2) + '')
				// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				let val = Math.floor(((value * 1) * 100).toPrecision(12))
				return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			}
		}
	},
	methods: {
		payTypeAll(type) {
			let data = {}
			this.$api.payTypeAll(data).then(res=> {
				if(res.status == 1){
					res.data.map(item=>{
						if(item.id == type){
							this.payList =item 
						}
					})
				}
				else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}					
			})
		},
		getStoreMoneyGroup() {
			this.$api.getStoreMoneyGroup({
				store_id: this.id
			}).then(res => {
				if (res.status == 1) {
					res.data.splice(0, 0, '全部')
					this.moneyArr = JSON.parse(JSON.stringify(res.data))

				}
			})
		},
		getData() {
			this.alipayList()
		},
		alipayList() {
			let data = {
				state: this.state,
				pay_type: this.$route.meta.pay_type,
				page: this.page,
				size: this.pageSize,
			}
			this.disLoading = true
			this.$api.alipayList(data).then(res => {
				this.loading = false;
				this.disLoading = false;
				if (res.status === 1) {
					this.tableData = res.data.data;
					this.total = res.data.total
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(error => {
				console.log(error)
				this.loading = false
				this.disLoading = false
			})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.page = 1
			this.alipayList()
		},
		handleCurrentChange(val) {
			this.page = val
			this.alipayList()
		},
		handleSelectionChange(val) {
			// console.log(val)
			this.multipleSelection = val;
		},
		deleteAll() {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				qr_id: dataId.join(',')
			}
			console.log(data)
			this.deleteStoreQr(data)
		},
		stateAll(state) {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				state: state,
				qr_id: dataId.join(',')
			}
			console.log(data)
			this.setStoreQrState(data)
		},
		deleteQr(item) {
			let data = {
				id: item.id
			}
			this.deleteStoreQr(data)
		},
		setState(item) {
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_url
			let data = {
				id: this.dataDetails.id,
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					qrcode_link: this.dataDetails.qrcode_link,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state: this.dataDetails.state == 0?1:0,
					pay_type: this.$route.meta.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold
			}
			this.$api.editAlipay(data).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.close()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			}).catch(error=>{
				console.log(error)
				this.close()
			})
		},
		setStoreQrState(data) {
			this.$confirm(`确定改变选择收款码的启停状态吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				console.log('==============')
				this.$api.setStoreQrState(data).then(res => {
					console.log("res")
					this.operating = false
					if (res.status == 1) {
						this.alipayList()
					} else {
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
		deleteStoreQr(data) {
			this.$confirm(`确定删除支付宝吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				this.$api.deleteAlipay(data).then(res => {
					this.operating = false
					if (res.status == 1) {
						this.alipayList()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
		showQr(url) {
			this.showPopup = true
			document.getElementById('qr').innerHTML = '';
			var qrcode = new QRCode2(this.$refs.qrCodeUrl, {
				text: url, // 需要转换为二维码的内容
				width: 400,
				height: 400,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode2.CorrectLevel.H
			})
		},
		closePopup() {
			this.showPopup = false
		},
		newqr() {

			let queryData = {}
			queryData['id'] = this.id
			// this.$router.push({
			// 	path: '/passageAlisub2',
			// 	query: queryData,
			// })
			this.dialogType = 'new'
			this.dialogTitle = "新增"
			this.dialogVisible = true
		},

		close() {
			this.dialogVisible = false
			this.dialogloading = false
			this.dialogTitle = ''
			this.dialogType = ""
			this.item = {}
			this.storeInfo = {}
			this.aliState = true
			this.fileurl = null
			this.fileurlFriends = null
			this.dataDetails = {
				true_name: "",
				telephone: "",
				state: 0,
				account: '',
				nickname: '',
				callback_id: '',
				qrcode_url: '',
				frcode_url:'',
				ali_userid:'',
				frcode_link:'',
				qrcode_link:'',
				eve_used_num:'',
				eve_used_money:'',
				gold: '',
				total_num: '',
				match_gold: '',
			}
		},
		edit(item) {
			this.dialogType = 'show'
			this.dialogTitle = '编辑'
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_link
			this.fileurlFriends = this.dataDetails.frcode_link
			this.aliState = this.dataDetails.state == 0?true:false
			this.dialogVisible = true

		},
		sure() {
			if(this.dialogType == 'new'){
				let data = {
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state:  this.dataDetails.state,
					pay_type: this.$route.meta.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					frcode_link: this.dataDetails.frcode_link,
					qrcode_link: this.dataDetails.qrcode_link,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold
				}
				if (isEmpty(this.dataDetails.account, "请输入账号")) {
					return
				}
				// if (isEmpty(this.dataDetails.nickname, "请输入昵称")) {
				// 	return
				// }
				if (isEmpty(this.dataDetails.true_name, "请输入真实姓名")) {
					return
				}
				if (isEmpty(this.dataDetails.gold, "请输入收款金额")) {
					return
				}
				if (isEmpty(this.dataDetails.total_num, "请输可收笔数")) {
					return
				}
				if (isEmpty(this.dataDetails.match_gold, "请输入匹配金额")) {
					return
				}

				this.disLoading = true
				this.$api.addAlipay(data).then(res => {
					
					this.disLoading = false
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}else{
				let data = {
					id: this.dataDetails.id,
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					nickname: this.dataDetails.nickname,
					telephone: this.dataDetails.telephone,
					qrcode_url: this.dataDetails.qrcode_url,
					frcode_url: this.dataDetails.frcode_url,
					ali_userid: this.dataDetails.ali_userid,
					state:  this.dataDetails.state,
					pay_type: this.$route.meta.pay_type, // 支付宝
					callback_id: this.dataDetails.callback_id,
					frcode_link: this.dataDetails.frcode_link,
					qrcode_link: this.dataDetails.qrcode_link,
					eve_used_num: this.dataDetails.eve_used_num,
					eve_used_money: this.dataDetails.eve_used_money,
					gold: this.dataDetails.gold,
					total_num: this.dataDetails.total_num,
					match_gold: this.dataDetails.match_gold,
				}
				
				if (isEmpty(this.dataDetails.account, "请输入账号")) {
					return
				}
				// if (isEmpty(this.dataDetails.nickname, "请输入昵称")) {
				// 	return
				// }
				if (isEmpty(this.dataDetails.true_name, "请输入真实姓名")) {
					return
				}
				if (isEmpty(this.dataDetails.gold, "请输入收款金额")) {
					return
				}
				if (isEmpty(this.dataDetails.total_num, "请输入可收笔数")) {
					return
				}
				if (isEmpty(this.dataDetails.match_gold, "请输入匹配金额")) {
					return
				}
				// if (isEmpty(this.dataDetails.year_total_money, "请输入年累计限额")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.eve_used_money, "请输入日收上限")) {
				// 	return
				// }
				// if (isEmpty(this.dataDetails.eve_used_num, "请输入日收笔数")) {
				// 	return
				// }
				// if (isEmpty(this.listIn, "请选择支付类型")) {
				// 	return
				// }
				this.disLoading = true
				this.$api.editAlipay(data).then(res => {
					this.disLoading = false
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
			// let data = {
			// 	qr_id: this.item.id,
			// 	note: this.item.note,
			// }
			// this.$api.editStoreQr(data).then(res => {
			// 	if (res.status == 1) {
			// 		this.close()
			// 		this.alipayList()
			// 		this.$message({
			// 			type: 'success',
			// 			message: res.msg
			// 		})
			// 	} else {
			// 		this.$message({
			// 			type: 'error',
			// 			message: res.msg
			// 		})
			// 	}
			// })

		},
		changeAliState(e) {
			console.log(e,'==okl')
			this.dataDetails.state= e?0:1
			console.log(this.dataDetails.state,'this.dataDetails.state')
		},
		resolveQR(event) {
			this.uploadQrcode(event.file)
			const result = this.getQrUrl(event.file)
			result.then(res => {

				if (res.data) {
					this.dataDetails.qrcode_url = res.data
					this.$message({
						type: 'success',
						message:'识别二维码成功!'
					})
				} else {
					this.$message({
						type: 'error',
						message:'识别二维码失败, 请重新上传!'
					})
				}
			}).catch(err => {
				console.log(err, '识别异常')
			})
		},
		resolveQRFriends(event){			
			this.uploadQrcode(event.file,'friends')
			const result = this.getQrUrl(event.file,'friends')
			result.then(res => {
				if (res.data) {
					this.dataDetails.frcode_url = res.data
					this.$message({
						type: 'success',
						message:'识别二维码成功!'
					})
				} else {
					this.$message({
						type: 'error',
						message:'识别二维码失败, 请重新上传!'
					})
				}
			}).catch(err => {
				console.log(err, '识别异常')
			})

		},
		changeImg(file, fileList) {
			this.fileList = [file.raw]
			this.resolveQR(file.raw)

		},
		getQrUrl(file,type) {
			var url = null;
			if (window.createObjectURL) { // basic
				url = window.createObjectURL(file);
			} else if (window.URL) { // mozilla(firefox)
				url = window.URL.createObjectURL(file);
			} else if (window.webkitURL) { // webkit or chrome
				url = window.webkitURL.createObjectURL(file);
			}
			if(type == 'friends'){
				this.fileurlFriends = url
			}else{
				this.fileurl = url
			}
			
			// 初始化
			const qr = new QrCode()
			// 解析二维码，返回promise
			return qr.decodeFromImage(url)
		},
		showBigImg(title,img){
			//查看大图
			this.qrImgTitle = title
			if(img){
				this.showImg = img
				this.innerVisibleImg = true
			}			
		},
		uploadQrcode(file,type){
			let formData = new FormData()
			formData.append('file', file)
			formData.append('username',local.get('bpcusername'))
			formData.append('tid',local.get('bpctid'))
			this.disLoading = true
			this.$api.uploadQrcode(formData).then(res => {
				this.disLoading = false
				if (res.status === 1) {
					if(type == 'friends'){
						this.dataDetails.frcode_link = res.data
					}else{
						this.dataDetails.qrcode_link = res.data
					}
					this.$message({
						type: 'success',
						message: res.msg
					})
				}else{
					if(type == 'friends'){
						this.dataDetails.frcode_link = ''
					}else{
						this.dataDetails.qrcode_link = ''
					}
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
				
			})
				
		},
		opened(){
			// let doc = document.getElementById('myDialog')
			// var domName = document.querySelector('#myDialog .el-dialog');
			// 	domName.style.background = 'transparent'
			// 	domName.style['box-shadow'] = 'none'
		}


	},
	watch: {

		total(val, old) {
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
			})
		}
	},

};
</script>

<style scoped="scoped" lang="scss">
@import '@/style/variables.scss';

.dataKey {
	display: inline-block;
	flex: 0 0 100px;
	text-align: left;
}

.qrIcon {
	display: inline-block;
	background: url(../../assets/image/qr.png);
	width: 30px;
	height: 30px;
	background-size: 100% 100%;
}

.errorDiv {
	white-space: nowrap;
	z-index: 2;
	position: absolute;
	top: 0;
	font-size: 10px;
	color: $danger
}

#uploadImg {
	display: none;
}

.upload-img {
	width: 100px;
	height: 100px;
}

.popup {
	position: fixed;
	left: 0;
	top: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 9999;

	&.show {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode {
		display: inline-block;

		img {
			width: 400px;
			height: 400;
			background-color: #fff; //设置白色背景色
			padding: 20px; // 利用padding的特性，挤出白边
			box-sizing: border-box;
		}
	}
}
.image-dialog{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}
</style>
